























































































import { Component, Inject, Prop } from 'vue-property-decorator';
import { BIcon, BLink } from 'bootstrap-vue';
import { Getter, namespace } from 'vuex-class';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import FlagComponent from '@/components/FlagComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import ProductImage from '@/models/graphql/ProductImage';
import Exhibitor from '@/models/graphql/Exhibitor';
import CommunityUser from '@/models/graphql/CommunityUser';
import FileResource from '@/models/graphql/FileResource';
import { CommunityUserBookmarkFilter } from '@/graphql/_Filters/CommunityUserBookmarkFilter';
import CommunityUserBookmark from '@/models/graphql/CommunityUserBookmark';
import PillWidget from '@/components/pill/PillWidget.vue';
import { deepGet } from '@/utils/ObjectHelpers';
import Community from '@/models/graphql/Community';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import EntityType from '@/utils/enums/EntityType';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import BaseCardWidget from '@/components/cards/BaseCardWidget.vue';

const communityUserBookmarkStore = namespace('CommunityUserBookmarkStore');

@Component({
  components: {
    FontAwesomeComponent,
    PillWidget,
    ButtonIconComponent,
    BIcon,
    BLink,
    FlagComponent,
  },
  inheritAttrs: false,
})
/* eslint-disable no-underscore-dangle */
export default class LargeProductCardWidget extends BaseCardWidget {
  @Getter
  authUser!: CommunityUser;

  @Getter
  community!: Community;

  @Prop({ required: true, default: '' })
  readonly uid!: string;

  @Prop({ required: true, default: -1 })
  readonly id!: number;

  @Prop({ required: true, default: '' })
  readonly name!: string;

  @Prop({ required: false, default: null })
  readonly exhibitor!: Exhibitor;

  @Prop({ required: true, default: '' })
  readonly images!: ProductImage[];

  @Prop({ default: false })
  readonly featured!: boolean;

  @Prop({ default: null })
  readonly cardRoute!: string;

  @Prop({ required: false, default: null })
  readonly _isBookmarked!: string | null;

  @Prop({ default: false })
  readonly _isRecommendedForMe!: boolean;

  @Prop({ default: false })
  readonly _isExhibitorFeatured!: boolean;

  @Prop({ default: 'DependencyWidgetStore' })
  readonly context!: string;

  @Prop({ required: false, default: true })
  readonly displayRecommendedBadge!: boolean;

  @Prop({ required: false, default: true })
  readonly displayFeaturedBorder!: boolean;

  @communityUserBookmarkStore.Action
  bookmark!: (payload: CommunityUserBookmarkFilter) => Promise<CommunityUserBookmark | undefined>;

  @communityUserBookmarkStore.Action
  unBookmark!: (payload: CommunityUserBookmarkFilter) => Promise<CommunityUserBookmark | undefined>;

  @Getter
  protected featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @Inject({ from: 'breakpoint' })
  private readonly breakpoint!: { value: string };

  private empty = this.image === null;

  private bookmarked = this._isBookmarked;

  private FileResourceHelper = FileResourceHelper;

  private get handleBaseUrl(): string | null {
    if (this.cardRoute) {
      let r = this.cardRoute;
      const matches = this.cardRoute.match(/(%[a-zA-Z-_.[0-9\]*]+%)/gs);
      if (matches) {
        matches.forEach((m) => {
          const prop = m.replaceAll('%', '').trim();
          const newValue = deepGet(this.$props, prop);
          if (newValue) {
            r = r.replaceAll(m, newValue);
          }
          if (this.$route.params[prop]) {
            r = r.replaceAll(m, this.$route.params[prop]);
          }
        });
        return r;
      }
      if (r[r.length - 1] === '/') {
        return `${r}${this.uid}`;
      }
      return `${r}/${this.uid}`;
    }
    return null;
  }

  private get image(): FileResource | null {
    return this.images
    && this.images.length > 0
    && 'fullFileResource' in this.images[0]
    && this.images[0].fullFileResource
      ? this.images[0].fullFileResource
      : null;
  }

  private get isExhibitorFeatured(): boolean {
    return this._isExhibitorFeatured
        && this.featureByKey(FeatureKeys.COMMUNITY_PRODUCTS_FEATURED_FEATURE)
        && this.featureByKey(FeatureKeys.COMMUNITY_PRODUCTS_FEATURED_FEATURE).enabled;
  }

  private toggleBookmark(): void {
    if (!this.authUser) {
      this.$bvModal.show('sign-in-action-modal');
      return;
    }
    if (this.bookmarked) {
      const temp = this.bookmarked;
      this.bookmarked = null;
      this.unBookmark({ uid: temp })
        .catch(() => {
          this.bookmarked = temp;
        });
    } else {
      this.bookmarked = 'bookmarked';
      this.bookmark({
        userId: this.authUser.uid,
        linkedUserId: this.uid,
        entityType: EntityType.LARGE_PRODUCT,
      }).then((response) => {
        this.bookmarked = response?.uid || '';
        if (this.bookmarked) {
          this.$logger.logMatomoStats(
            this.authUser,
            this.community.code as string,
            EntityType.LARGE_PRODUCT,
            StatLoggerActions.ADD,
            '',
            this.id,
            this.uid,
            StatLoggerCategories.BOOKMARK,
            this.$i18n.locale,
          );
        }
      }).catch(() => {
        this.bookmarked = null;
      });
    }
  }
}
